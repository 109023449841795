var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "dn-subscribe-success dn-container" }, [
    _c("div", _vm._b({}, "div", _vm.heroAttrs, false)),
    _c("div", { staticClass: "dn-subscribe-success__body" }, [
      _c("h1", { staticClass: "dn-subscribe-success__title" }, [
        _vm._v("Welcome to Drumnow!"),
      ]),
      _c("p", [
        _vm._v(
          " Thank you for creating your account with Drumnow. We have been curating the best of the best for you and are most excited for you to check out what's in store. We have hundreds of drum loops that can be streamed in various formats to cater to any writing technique. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " As a Core subscriber, you get full streaming access to our entire library for one monthly fee! You can then download up to 150 individual loops from any pack per month as long as you're a subscriber. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " To get started, build your library by browsing, streaming loops and purchasing packs. The packs you purchase will be added to your personal collection, from there you can download as many times as you want. "
        ),
      ]),
      _c("div", { staticClass: "dn-subscribe-success__cta-section" }, [
        _c("h3", [_vm._v("One last thing before you begin.")]),
        _c("p", [
          _vm._v(
            " Head to your profile and add an image and your name. Then get creating! "
          ),
        ]),
        !_vm.loading
          ? _c(
              "button",
              {
                directives: [{ name: "ripple", rawName: "v-ripple" }],
                staticClass: "dn-btn dn-landing__form__submit",
                attrs: { disabled: _vm.disableCreate },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("settings?edit=true")
                  },
                },
              },
              [_vm._v(" Go to Profile ")]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }